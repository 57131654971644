<template>
  <div id="app">
    <router-view
      name="appheader"
      v-if="user"
      :reflections="reflections"
    ></router-view>
    <main v-if="user">
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view
          :reflections="sortedReflections"
          :reflectionsArchive="reflectionsArchive"
          :goals="goals"
          :tasks="tasks"
          :archiveFullyLoaded="archiveFullyLoaded"
          :notifications="notifications"
          :groupPosts="groupPosts"
          :loaded="loaded"
          ref="mrWrapper"
        />
      </fade-transition>
    </main>
    <router-view name="appfooter" :user="user" v-if="user"></router-view>
    <div v-if="!user">Preparing My Reflections</div>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "My Reflections - Self-reflection. Self-awareness. Self-improvement.",
      // all titles will be injected into this template
      titleTemplate: "%s :: My Reflections",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: "My Reflections - Self-reflection. Self-awareness. Self-improvement.",
        },
        {
          vmid: "description",
          name: "description",
          content:
            "A beautiful, simple to application that will improve your professional &amp; personal reflection habits to fast track your growth and development!",
        },
        // FACEBOOK / GRAPH
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://myreflections.app/",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "My Reflections - Self-reflection. Self-awareness. Self-improvement.",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content:
            "A beautiful, simple to application that will improve your professional &amp; personal reflection habits to fast track your growth and development!",
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "https://myreflections.app/img/banner.png",
        },
        // TWITTER
        {
          vmid: "twitter:card",
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          vmid: "twitter:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "twitter:url",
          property: "twitter:url",
          content: "https://myreflections.app/",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: "My Reflections - Self-reflection. Self-awareness. Self-improvement.",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content:
            "A beautiful, simple to application that will improve your professional &amp; personal reflection habits to fast track your growth and development!",
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: "https://myreflections.app/img/banner.png",
        },
      ],
    };
  },
  components: {
    FadeTransition,
  },

  data() {
    return {
      loaded: {
        reflections: false,
        groupIds: false,
        groupPosts: false,
        goals: false,
        tasks: false,
        notifications: false,
      },
      reflections: [],
      groupIds: [],
      groupPosts: [],
      goals: [],
      tasks: [],
      personalNotifications: [],
      communityNotifications: [],
      // oldestReflection: 0,
      offsetTop: 0,

      reflectionsArchive: [],
      querying: false,
      archiveFullyLoaded: false
      
    };
  },
  created: function() {
    window.addEventListener("appinstalled", this.pwaInstalled);
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed: function() {
    window.removeEventListener("appinstalled", this.pwaInstalled);
    window.removeEventListener('scroll', this.onScroll);
  },

  watch: {
    user: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler(newVal, oldVal) {
        if (newVal.loggedIn === true) {
          if (this.loaded.reflections === false) {
            this.getReflections();
          }
          if (this.loaded.goals === false) {
            this.getGoals();
          }
          if (this.loaded.tasks === false) {
            this.getTasks();
          }
        }
      },
    },
    groups: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.length > 0 && this.loaded.groupIds === false) {
          this.getGroupIds();
        }
      },
    },
    oldestReflection: function(newVal, oldVal) {

      if (newVal > 0) {

        if (this.loaded.notifications === false) {
          // setTimeout(this.getNotifications,500)
          this.getNotifications(newVal);
        }

        if (this.loaded.groupPosts === false) {
          // setTimeout(this.getGroupPosts,500)
          // this.getGroupPosts(newVal);
          this.loaded.groupPosts = true;
        }
      }
    },
    offsetTop() {
      this.callbackFunc();
    },

  },

  mounted() {
    setTimeout(this.documentReferrer, 500);
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
      reflectionActions: "reflectionActions"
    }),

    sortedReflections() {
      var content = this.reflections;
      return content.sort((b, a) => (a.creationSeconds > b.creationSeconds ? 1 : -1));
    },

    oldestReflection(){

      if(this.sortedReflections.length === 0){

          let ourDate = new Date();
          let notificationDate = new firebase.firestore.Timestamp.fromDate(ourDate);
          return notificationDate.seconds

      }

      let index = this.sortedReflections.length-1
      let ourDate = new Date();
      let notificationDate = new firebase.firestore.Timestamp.fromDate(ourDate);

      return this.sortedReflections[index].creationSeconds ? this.sortedReflections[index].creationSeconds : notificationDate.seconds
    },

    notifications() {
      return this.communityNotifications
        .concat(this.personalNotifications);
    },

  },
  methods: {

    isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },

    callbackFunc() {

      if(!this.$refs['mrWrapper']){
        return
      }

      if(!this.$refs['mrWrapper'].$refs['loadMoreDiv']){
        return
      }

      let loadmore = this.$refs['mrWrapper'].$refs['loadMoreDiv'];
      if(!loadmore){
        return
      }

      if (this.isElementInViewport(loadmore) && !this.querying) {
          this.loadArchives();
      }
      

    },

    onScroll (e) {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
    },

    documentReferrer() {
      var referrer = document.referrer.includes(
        "android-app://app.myreflections.www"
      );
      if (referrer) {
        this.$store.commit("SET_ANDROID_DETECTED", true);
      }
    },

    pwaInstalled() {
      this.$analytics.logEvent("pwa_installed");
    },

    getTagData(tag){

      let tagData = this.user.data.tags.filter((t) => {
        return t.name === tag
      });

      if(tagData.length === 1){
        tagData[0].label = tagData[0].name
        tagData[0].scope = "personal"
        return tagData[0]
      }

    },

    getReflections() {
      const vm = this;

      if (vm.user.loggedIn && vm.user.data && vm.user.data.uid) {
        // console.log('Getting your reflections')

        var docRef = db.collection("reflections");
        docRef = docRef.where("uid", "==", vm.user.data.uid);
        docRef = docRef.orderBy("creationSeconds", "desc");
        docRef = docRef.limit(20);
        docRef.onSnapshot(async function(docs) {
          docs.docChanges().forEach(function(change) {
            let { newIndex, oldIndex, doc, type } = change;
            let docData = doc.data();
            if(docData.tag && docData.tag !== 'none' && !docData.tagData){
              docData.tagData = vm.getTagData(docData.tag)
            }
            if (type === "added") {
              if(!docData.adjustments){
                docData.adjustments = [];
              }
              vm.reflections.splice(newIndex, 0, docData);
            } else if (type === "modified") {
              if(!docData.adjustments){
                docData.adjustments = [];
              }
              vm.reflections.splice(oldIndex, 1);
              vm.reflections.splice(newIndex, 0, docData);
            } else if (type === "removed") {
              vm.reflections.splice(oldIndex, 1);
            }
          });
        });

        vm.loaded.reflections = true;
      }
    },

    getGoals() {
      const vm = this;

      if (vm.user.loggedIn && vm.user.data && vm.user.data.uid) {
        // console.log('Getting your reflections')

        var docRef = db.collection("users").doc(vm.user.data.uid).collection("goals");
        docRef = docRef.where("status", "==", "active");
        docRef = docRef.orderBy("creationSeconds", "asc");
        docRef = docRef.limit(3);
        docRef.onSnapshot(async function(docs) {
          docs.docChanges().forEach(function(change) {
            let { newIndex, oldIndex, doc, type } = change;
            let docData = doc.data();
            if (type === "added") {
              vm.goals.splice(newIndex, 0, docData);
            } else if (type === "modified") {
              vm.goals.splice(oldIndex, 1);
              vm.goals.splice(newIndex, 0, docData);
            } else if (type === "removed") {
              vm.goals.splice(oldIndex, 1);
            }
          });
        });

        vm.loaded.goals = true;
      }
    },

    getTasks() {
      const vm = this;

      if (vm.user.loggedIn && vm.user.data && vm.user.data.uid) {
        // console.log('Getting your reflections')

        var docRef = db.collection("users").doc(vm.user.data.uid).collection("tasks");
        docRef = docRef.where("status", "==", "active");
        docRef = docRef.orderBy("creationSeconds", "asc");
        docRef.onSnapshot(async function(docs) {
          docs.docChanges().forEach(function(change) {
            let { newIndex, oldIndex, doc, type } = change;
            let docData = doc.data();
            if (type === "added") {
              vm.tasks.splice(newIndex, 0, docData);
            } else if (type === "modified") {
              vm.tasks.splice(oldIndex, 1);
              vm.tasks.splice(newIndex, 0, docData);
            } else if (type === "removed") {
              vm.tasks.splice(oldIndex, 1);
            }
          });
        });

        vm.loaded.tasks = true;
      }
    },

    getNotifications(creationCutOff) {
      const vm = this;

      let audience = []; // JSON.parse(JSON.stringify(this.groupdIds))
      audience.push("a-myreflections");
      if(this.user.administrativeUser){
        audience.push("a-myreflections-testing");
      }

      if (!vm.loaded.notifications && creationCutOff > 0) {
        // console.log('Getting your notifications')

        // User Notifications
        var docRef = db.collection("notifications");
        docRef = docRef.where("audience", "==", this.user.data.uid);
        docRef = docRef.where("alertHidden", "==", false);
        docRef = docRef.orderBy("creationSeconds", "desc");
        docRef = docRef.limit(20);
        docRef.onSnapshot(async function(docs) {
          docs.docChanges().forEach(function(change) {
            let { newIndex, oldIndex, doc, type } = change;
            if (type === "added") {
              vm.personalNotifications.splice(newIndex, 0, doc.data());
            } else if (type === "modified") {
              vm.personalNotifications.splice(oldIndex, 1);
              vm.personalNotifications.splice(newIndex, 0, doc.data());
            } else if (type === "removed") {
              vm.personalNotifications.splice(oldIndex, 1);
            }
          });
        });

        // Community Notifications
        var docRef = db.collection("communityNotifications");
        if(!vm.user.administrativeUser){
          docRef = docRef.where("published", "==", true);
        }
        docRef = docRef.where("creationSeconds", ">", vm.oldestReflection);
        docRef = docRef.orderBy("creationSeconds", "desc");
        docRef = docRef.limit(20);
        docRef.onSnapshot(async function(docs) {
          docs.docChanges().forEach(function(change) {
            let { newIndex, oldIndex, doc, type } = change;
            if (type === "added") {
              vm.communityNotifications.splice(newIndex, 0, doc.data());
            } else if (type === "modified") {
              vm.communityNotifications.splice(oldIndex, 1);
              vm.communityNotifications.splice(newIndex, 0, doc.data());
            } else if (type === "removed") {
              vm.communityNotifications.splice(oldIndex, 1);
            }
          });
        });

       vm.loaded.notifications = true;
      }
    },

    getGroupIds() {

      const vm = this;
      vm.groupIds = [];
      let tmpGroups = [];

      if (vm.groups && vm.groups.length > 0) {
        tmpGroups = _.cloneDeep(vm.groups);
        tmpGroups.forEach((group, gi) => {
          vm.groupIds.push(group.id);
        });

        vm.loaded.groupIds = true;
      }
    },

    oldestArchivedReflection(){

        if(this.reflectionsArchive.length > 0){
            let index = this.reflectionsArchive.length-1
            return this.reflectionsArchive[index].creationSeconds
        }else{
            return this.oldestReflection;
        }

    },

    loadArchives(){

      const vm = this;

      this.querying = true;

      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          user.getIdToken().then(function (idToken) {
            // Get our data!
            vm.bigQuery(idToken);
          });
        }
      });

    },

    bigQuery(idToken) {
      const vm = this;
      const axios = require("axios");

      let start = vm.oldestArchivedReflection();

      vm.$analytics.logEvent("reflection_feed_query", {
        uid: vm.user.data.uid,
        fullName: vm.user.data.fullName,
        start: start,
      });

      // Send token to your backend via HTTPS
      var config = {
        headers: {
          Authorization: "Bearer " + idToken,
        },
      };

      // let url = 'http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/reflection_feed_query?start=' + start;
      let url = 'https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/reflection_feed_query?start=' + start;

      // Make a request for a user with a given ID
      axios
        .get(url, config)
        .then(function (response) {
          // handle success
          // console.log(response.data)
          vm.convertReflections(response.data);

        })
        .catch(function (error) {
          // handle error
          // console.log(error)
        })
        .finally(function () {
          // always executed
          vm.querying = false;
          // vm.building = false
        });
    },

    convertReflections(data) {
      const vm = this;

      let feedCheck = [];
      let index;

      if(data.length < 50){
        vm.archiveFullyLoaded = true
      }

      data.forEach(function (entry, i) {

        let activityDetails = entry.activityDetails;
        let activityName = entry.activityName;
        let creationSeconds = parseInt(entry.creationSeconds);
        let creationTime = entry.creationTime;
        let createdByWebhook = entry.createdByWebhook;
        let id = entry.document_id;
        let attachment = [];
        let highlights = [];
        let lowlights = [];
        let adjustments = [];
        let dailyCheckinQuestions = [];
        let imageURL = entry.imageURL;
        let rating = entry.rating;
        let reflectionDate = entry.reflectionDate;
        let reflectionType = entry.reflectionType;
        let tag = entry.tag;
        let uid = entry.uid;

        // If we haven't see this reflection yet
        if (feedCheck.indexOf(id) === -1) {
          var rMap = {
            activityDetails: activityDetails,
            activityName: activityName,
            creationSeconds: creationSeconds,
            creationTime: creationTime,
            createdByWebhook: createdByWebhook,
            id: id,
            imageURL: imageURL,
            rating: rating,
            highlights: highlights,
            lowlights: lowlights,
            adjustments: adjustments,
            attachment: attachment,
            dailyCheckinQuestions: dailyCheckinQuestions,
            analysis: null,
            reflectionDate: reflectionDate,
            reflectionType: reflectionType,
            tag: tag,
            uid: uid,
            source: "archive",
          };

          // store our id in our check object
          feedCheck.push(id);

          // Create our reflection record
          index = vm.reflectionsArchive.push(rMap) - 1;

        }

        // // If we have a image, add it
        if (entry.highlights_member) {
          vm.reflectionsArchive[index].highlights.push(entry.highlights_member.replace(/^"(.+(?="$))"$/, '$1'));
        }
        if (entry.lowlights_member) {
          vm.reflectionsArchive[index].lowlights.push(entry.lowlights_member.replace(/^"(.+(?="$))"$/, '$1'));
        }
        if (entry.adjustments_member) {
          vm.reflectionsArchive[index].adjustments.push(entry.adjustments_member.replace(/^"(.+(?="$))"$/, '$1'));
        }
        if (entry.dailyCheckinQuestions_member) {
          vm.reflectionsArchive[index].dailyCheckinQuestions.push(JSON.parse(entry.dailyCheckinQuestions_member));
        }
        if (entry.attachment_member) {
          vm.reflectionsArchive[index].attachment.push(JSON.parse(entry.attachment_member));
        }
        if (entry.analysis_score_label) {
          vm.reflectionsArchive[index].analysis = {
            overall: {
              score_label: entry.analysis_score_label,
              score_tag: entry.analysis_score_tag,
              subjectivity: entry.analysis_subjectivity,
              intensity: {
                compound: entry.analysis_intensiy_compound,
                neg: entry.analysis_intensiy_neg,
                neu: entry.analysis_intensiy_neu,
                pos: entry.analysis_intensiy_pos
              }
            }
          };
        }

      });
    }

  },
};
</script>
